



























import { isMobile } from '@/@core/helpers/useBreakpoint';
import {
  computed,
  defineComponent,
  PropType
} from '@vue/composition-api';
import KAppBar from '../navigation/KAppBar.vue';

export default defineComponent({
  components: { KAppBar },
  props: {
    mobileOnly: {
      type: Boolean as PropType<boolean>,
      default: false
    },
    center: {
      type: Boolean as PropType<boolean>,
      default: false
    },
    'clipped-left': {
      type: Boolean as PropType<boolean>,
      default: false
    }
  },
  name: 'KPageHeader',
  setup({ mobileOnly }) {
    const showNavbar = computed(() =>
      mobileOnly ? isMobile.value : true
    );

    return {
      isMobile,
      showNavbar
    };
  }
});
