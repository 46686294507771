











import { defineComponent } from '@vue/composition-api'

export default defineComponent({
    name: 'KAppBar',
    setup() {
        const defaultAttrs = {
            app: false,
            fixed: false,
            dark: false,
            'clipped-left': false,
            color: 'light',
            elevation: '1',
        }

        return {
            defaultAttrs
        }
    }
})
